<template>
  <Container :title="$t('loanDetails.loanRef', {referenceNumber: data.reference_number})">
    <v-card elevation="2" :loading="loading">
      <loan-detail v-if="data.id" :loan="data"/>
    </v-card>
  </Container>
</template>

<script>
import Vue from 'vue'
import LoanDetail from "../components/LoanDetail"

export default Vue.extend({
  name: "Loan",
  components: {
    LoanDetail,
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      data: {},
    }
  },
  watch: {
    id: {
      immediate: true,
      handler() { this.fetch() },
    },
  },
  methods: {
    fetch() {
      this.loading = true
      this.$http
        .get(`/loans/${this.id}/`)
        .then((response) => {
          this.loading = false
          this.data = response.data
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>
